import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import { ActivityData } from './bluetooth.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  afAuth: firebase.auth.Auth;
  patientDictionary: Record<string, string> = {};
  patients: Patient[] = [];

  private userInfo: {
    name: string;
    email: string;
    id: string;
  };

  constructor(
    private storage: StorageService,
    private db: AngularFirestore) {
      this.afAuth = firebase.initializeApp(environment.firebaseClair, 'autecla-clair').auth();
     }

    login(email: string, password: string) {
      return new Promise((resolve, reject) => {

        this.afAuth.signInWithEmailAndPassword(email, password).then((res) => {
          this.userInfo = {
            name: res.user.displayName ?? 'Você',
            email: res.user.email,
            id: res.user.uid
          };

          this.storage.set('userInfo', this.userInfo);
          resolve(this.userInfo);

        }).catch((err) => {
          reject(err);
          return;
        });
      });
    }

    logout() {
      return new Promise<void>(resolve => {
        Promise.all([this.afAuth.signOut(), this.storage.clear()]).then(() => {
          resolve();
        });
      });
    }

    isAuthenticated() {
      return new Promise(resolve => {
        this.storage.get('userInfo').then((data) => {
          if (data != null) {
            this.userInfo = data;
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    }


    async addActivityData(activityData: ActivityData, hash) {
      const realm = this.db.collection('autoy-testing-realm');
      const user = realm.doc(this.userInfo.id);
      await user.set({}, {merge: true});

      const collection = user.collection('activities');

      activityData.timestamp = Date.now();

      collection.doc(hash).set(activityData);
    }

    async addPatient(patient: Patient) {
      const realm = this.db.collection('autoy-testing-realm');
      const user = realm.doc(this.userInfo.id);
      await user.set({}, {merge: true});

      const collection = user.collection('patients');
      collection.doc(patient.patientId).set(patient);
      const index = this.patients.findIndex(existingPatient => patient.patientId === existingPatient.patientId);
      if (index !== -1) {
        this.patients[index] = patient;
      } else {
        this.patients.push(patient);
      }

      this.patientDictionary[patient.patientId] = patient.patientName;
    }

    async retrieveHistory(): Promise<ActivityData[]> {
      const collection = this.db.collection<ActivityData>(`autoy-testing-realm/${this.userInfo.id}/activities`);
      return (await collection.get().toPromise()).docs.map((element) => element.data());
    }

    async retrievePatientDictionary() {
      const collection = this.db.collection<Patient>(`autoy-testing-realm/${this.userInfo.id}/patients`);
      this.patients = (await collection.get().toPromise()).docs.map((element) => element.data());

      for (const patient of this.patients) {
        this.patientDictionary[patient.patientId] = patient.patientName;
      }
    }

    getCurrentUserInfo() {
      return this.userInfo;
    }

}

export type Patient = {
  patientId: string;
  patientName: string;
};
