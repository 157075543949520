import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: FirebaseService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot): Promise <boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.authService.isAuthenticated().then((isLoggedIn) => {
        if (!isLoggedIn) {
          resolve(true);
        } else {
          this.router.navigate(['home']);
          resolve(false);
        }

      });
    });
  }
}
