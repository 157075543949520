// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  buildVersion: 'debug',
  firebase: {
    projectId: 'autoy-app',
    appId: '1:140460921412:web:1ff40e16f58ad17fd22211',
    storageBucket: 'autoy-app.appspot.com',
    apiKey: 'AIzaSyAxrSCYGSOrmLw06i3PPAOlM3Mf8JNIB-k',
    authDomain: 'autoy-app.firebaseapp.com',
    messagingSenderId: '140460921412',
  },
  firebaseClair: {
    projectId: 'autecla-clair',
    appId: '1:126905659721:web:9c06db0de26181f306aa98',
    storageBucket: 'autecla-clair.appspot.com',
    apiKey: 'AIzaSyAjwpV0SnRzVuj5n1rE8yLr3lMXINmk-ss',
    authDomain: 'autecla-clair.firebaseapp.com',
    messagingSenderId: '126905659721',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
